import stringConstants from "../constants/strings"
import { authHeader } from "../helpers/authHeader"

export const regularLineService = {
    getRegularLineAvailableStops,
    getRegularLinePossibleDropoffs,
    getRegularLineStopScheduledPickupHours
}


// endpoint only for type service with schedule
function getRegularLineAvailableStops(serviceId, lineId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        },
    }
    const url = lineId ? `/services/v1/regularLine/getRegularLineAvailableStops?serviceId=${serviceId}&lineId=${lineId}` : `/services/v1/regularLine/getRegularLineAvailableStops?serviceId=${serviceId}`
    return fetch(
        stringConstants.URL_ApiGateway + url,
        requestOptions,
    ).then(handleResponse)
}

function getRegularLinePossibleDropoffs(dateTime, stopId) {
    let body = {
        'requestPickUpStartTime': dateTime,
        'pickupStopId': stopId
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(body)
    }
    const url = `/services/v1/regularLine/getRegularLinePossibleDropoffs`
    return fetch(
        stringConstants.URL_ApiGateway + url,
        requestOptions,
    ).then(handleResponse)
}

function getRegularLineStopScheduledPickupHours(dateTime, stopIdPickUp, stopIdDropOff) {
    let body = {
        'requestPickUpStartTime': dateTime,
        'pickupStopId': stopIdPickUp,
        'dropoffStopId': stopIdDropOff
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(body)
    }
    const url = `/services/v1/regularLine/getRegularLineStopScheduledPickupHours`
    return fetch(
        stringConstants.URL_ApiGateway + url,
        requestOptions,
    ).then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)
        if (response.status === 204 || response.status === 404 || data.statusCode == 204 || data.statusCode == 404) {
            return []
        }
        if (!response.ok) {
            const error = (data && data.message) || text || response.status
            return Promise.reject(error)
        }
        return data
    })
}