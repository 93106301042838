import React from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon } from 'google-maps-react';
import ReactDOM from "react-dom";
import stringConstants from '../../constants/strings';
import { Grid } from '@material-ui/core';
import Spinner from '../shared/spinner';
import TripMap from '../trips/maps';
import ResumeTrip from '../trips/form/resumeTrip';
import RequestTripForm from '../trips/form/requestTrip';
import { MY_CUSTOM_ADDRESS } from '../../redux/actions/trip_actions';
import CancelIcon from '@material-ui/icons/Cancel';
import { calculateTimePoints } from '../../helpers/google';
import store from '../../redux/store';
import { alertActions } from '../../redux/actions/alert_actions';
import i18next from 'i18next';
import { withTheme } from '@material-ui/core/styles';
import { REGULAR_SERVICES_TYPES, SERVICES_TYPE } from '../../constants/types';
import ModalSelectPosibleSchedule from '../custom/modalSelectPosibleSchedule';
import { zoneStopTypes } from '../../constants/zoneStopTypes';

const ORIGIN = 0;

class HomeView extends React.Component {
    constructor(props) {
        super();
        this._isMounted = false;
        this.state = {
            currentPosition: {
                lat: 41.387744,
                lng: 2.170274
            },//null,
            showingInfoWindow: false,  // Hides or shows the InfoWindow
            activeMarker: {},          // Shows the active marker upon click
            selectedPlace: {},         // Shows the InfoWindow to the selected place upon a marker
            markers: [],
            pickupTime: null, //moment().format("YYYY-MM-DD[T]HH:mm"),
            passengers: 1,
            luggage: 0,
            wheelchair: false,
            babyCart: false,
            region: null,
            bounds: null,
            destinationAddress: '',
            originAddress: '',
            ticked: false,
            clear: false,
            distanceString: '',
            showStopName: false,
            originMarkerObjects: [],
            destinationMarkerObjects: [],
            onlyReturn: null,
            onlyOutbound: null,
            direction: 'outbound'
        }
        this.getCurrentPosition = this.getCurrentPosition.bind(this);
        this.onClickZone = this.onClickZone.bind(this)
        this.child = React.createRef();


    }

    setOriginAndDestination(id, name, point) {
        this.child.current.setOriginAndDestination(id, name, point)
    }




    onClickZone() {
        this.setState({
            hideOriginDestination: !this.state.hideOriginDestination,
            originStop: '',
            destinationStop: ''
        })
    }

    componentDidMount() {
        //this.getCurrentPosition();
        this.props.getCustomerProfile();
    }

    componentWillUnmount() {
        this.props.setTrip(null);
        this.props.cleanRepeatTrip();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tripReducer.stopsType != prevProps.tripReducer.stopsType) {
            if (this.props.tripReducer.stopsType != null) {
                this.bounds(this.props.tripReducer.stopList);
            }
        }
    }

    getCurrentPosition() {
        const { t } = this.props
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                this.setState({ currentPosition: pos });
            });
        } else {
            alert(t('home.errorLoadingMap'))
        }
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        }, () => console.log("this.state.selectedPlace=> ", this.state.selectedPlace));
    }
    onMarkerOver = (props, marker, e) => {
        if (this.state.showStopName == false) {
            this.setState({
                selectedPlace: props,
                activeMarker: marker,
                showStopName: true
            })
        }
    }

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    showMarkers = () => {
        const { t } = this.props
        if (this.props.tripReducer.showStops == false) {
            if (this.state.markers.length > 0) {
                return this.state.markers.map((marker, i) => {
                    if (marker != null) {
                        return (
                            <Marker
                                key={i}
                                visible={marker.hidden ? false : true}
                                position={{ lat: marker.position.lat, lng: marker.position.lng }}
                                name={marker.name == ORIGIN ? t('home.origin') : t('home.destination')}
                                onClick={this.onMarkerClick}
                            />
                        )
                    }
                })
            }
        }
    }

    customControl(controlDiv, map, title, text, direction) {
        const controlUI = document.createElement("div");

        controlUI.style.backgroundColor = "#fff";
        controlUI.style.color = "#fff";
        controlUI.style.border = "0.6rem solid"
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.cursor = "pointer";
        controlUI.style.marginTop = "8px";
        controlUI.style.marginBottom = "22px";
        controlUI.style.textAlign = "center";
        controlUI.title = title
        controlUI.id = "div_" + direction
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        const controlText = document.createElement("div");

        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "Roboto,Arial,sans-serif";
        controlText.style.fontSize = "16px";
        controlText.style.lineHeight = "38px";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = text
        controlUI.appendChild(controlText);
        if (direction == this.state.direction) {
            controlUI.style.color = this.props.theme.palette.primary.main
            controlUI.style.border = '0.6rem solid'
        }
        // Setup the click event listeners: simply set the map to Chicago.
        controlUI.addEventListener("click", () => {
            //map.setCenter(chicago);
            if (this.props.tripReducer.selectedOriginStop == null) {
                this.setState({
                    onlyOutbound: direction === "outbound" ? true : null,
                    onlyReturn: direction === "return" ? true : null,
                    direction
                })
                controlUI.style.color = this.props.theme.palette.primary.main
                controlUI.style.border = '0.6rem solid'
                let oppositiveDiv = document.getElementById('div_' + (direction == 'return' ? 'outbound' : 'return'))
                if (oppositiveDiv) {
                    oppositiveDiv.style.color = "#fff"
                    oppositiveDiv.style.backgroundColor = "#fff"
                    oppositiveDiv.style.border = "0.6rem solid"
                }
            }


        });
    }

    bounds(array) {
        var points = [];
        for (var i = 0; i < array.length; i++) {
            points.push({ lat: array[i].point.lat, lng: array[i].point.lon })
        }
        var bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }
        this.setState({
            bounds
        });
    }

    clearSelectedOriginStop = () => {

        const service = this.props.tripReducer.selectedService
        if (!REGULAR_SERVICES_TYPES.includes(service.serviceType))
            this.props.clearOrigin(this.props.tripReducer.selectedService, 'origin', this.props.tripReducer.selectedService.originZone.zoneStops, this.props.tripReducer.originalData);
        else {
            let origins = []
            let destinations = []
            if (service.serviceType == SERVICES_TYPE.regularService) {
                service.serviceLine.outbound.zoneStops.forEach(function (element, i) {
                    element.outbound = true
                    element.outboundIndex = i
                    element.allowOppositeDirections = service.serviceLine.outbound.allowOppositeDirections
                    origins.push(element)
                    destinations.push(element)
                });
                service.serviceLine.return.zoneStops.forEach(function (element, i) {
                    element.return = true
                    element.returnIndex = i
                    element.allowOppositeDirections = service.serviceLine.return.allowOppositeDirections
                    origins.push(element)
                    destinations.push(element)
                });
            } else {
                service.regularLines.forEach(function (line) {
                    line.outbound.zoneStops.forEach(function (stop) {
                        stop.outbound = true
                        if (!origins.find(item => item.id == stop.id))
                            origins.push(stop)
                    })
                })
            }
            this.props.clearOrigin(this.props.tripReducer.selectedService, 'origin', origins, this.props.tripReducer.originalData);
        }
    }


    checkServiceLine() {
        if (this.props.tripReducer.selectedService && this.props.tripReducer.selectedService.serviceType == SERVICES_TYPE.regularService && this.map?.map) {
            const centerControlDivOrigin = document.createElement("div");
            centerControlDivOrigin.style.padding = 10
            const centerControlDivDestinations = document.createElement("div");
            centerControlDivDestinations.style.padding = 10
            if (document.getElementById('div_outbound'))
                document.getElementById('div_outbound').hidden = this.props.tripReducer.selectedOriginStop != null
            if (document.getElementById('div_return'))
                document.getElementById('div_return').hidden = this.props.tripReducer.selectedOriginStop != null


            this.customControl(centerControlDivOrigin, this.map.map, i18next.t('home.titleButton'), i18next.t('home.outbound'), "outbound")
            this.customControl(centerControlDivDestinations, this.map.map, i18next.t('home.titleButton'), i18next.t('home.return'), "return")
            if (this.map.map.controls[window.google.maps.ControlPosition.TOP_CENTER].length > 0)
                this.map.map.controls[window.google.maps.ControlPosition.TOP_CENTER] = []
            this.map.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDivOrigin);
            if (this.props.tripReducer.selectedService.serviceLine.hasReturn)
                this.map.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(centerControlDivDestinations);
        } else {
            if (document.getElementById('div_outbound'))
                document.getElementById('div_outbound').hidden = true
            if (document.getElementById('div_return'))
                document.getElementById('div_return').hidden = true


        }
    }


    showStopsMarkers = (map) => {
        const { stopList } = this.props.tripReducer;
        let pin_pickUp = process.env.PUBLIC_URL + "/content/img/marker-get-on.png";
        let pin_dropOff = process.env.PUBLIC_URL + "/content/img/marker-get-off.png";
        let pin_myAddress = process.env.PUBLIC_URL + "/content/img/marker-user-blue.png";
        let pin_pickUp_myAddress = process.env.PUBLIC_URL + "/content/img/marker-get-on-blue.png";
        let pin_dropOff_myAddress = process.env.PUBLIC_URL + "/content/img/marker-get-off-blue.png";
        if (this.props.tripReducer.showStops == true && stopList.length > 0 && this.props.tripReducer.stopsType == 'origin') {
            //return this.props.tripReducer.stopList.map((stop, index) => {
            return stopList.map((stop, index) => {
                return (
                    <Marker
                        ref={marker => {
                            this.originStopMarker = marker;
                        }}
                        key={index}
                        position={{ lat: stop.point.lat, lng: stop.point.lon }}
                        id={stop.id}
                        name={stop.name}
                        address={stop.address}
                        onClick={stop.selectable == false ? null : this.onMarkerClick}
                        allowOppositeDirections={stop.allowOppositeDirections}
                        return={stop.return}
                        outbound={stop.outbound}
                        outboundIndex={stop.outboundIndex}
                        returnIndex={stop.returnIndex}
                        visible={(stop.outbound && this.state.direction == 'return') ? false : (stop.return && this.state.direction == 'outbound') ? false : true}
                        icon={{
                            url: stop.id == MY_CUSTOM_ADDRESS && !window.selectAutoCompleteOrigin ? pin_myAddress : stop.myAddressIsEqualStop == true && !window.selectAutoCompleteOrigin ? pin_pickUp_myAddress : pin_pickUp  /*this.props.tripReducer.stopsType == 'origin' ? pin_pickUp : pin_dropOff*/,
                            scaledSize: /*stop.id == MY_CUSTOM_ADDRESS ? new window.google.maps.Size(60, 50) :*/ new window.google.maps.Size(50, 70),
                        }}
                    />
                )
            })

        } else if (this.props.tripReducer.showStops == true && stopList.length > 0 && this.props.tripReducer.stopsType == 'destination') {
            return stopList.map((stop, index) => {
                if (stop.id != this.props.tripReducer.selectedOriginStop?.id || stop.id == MY_CUSTOM_ADDRESS) {
                    return (
                        <Marker
                            ref={marker => {
                                this.destinationStopMarker = marker;
                            }}
                            key={index}
                            position={{ lat: stop.point.lat, lng: stop.point.lon }}
                            return={stop.return}
                            outbound={stop.outbound}
                            outboundIndex={stop.outboundIndex}
                            returnIndex={stop.returnIndex}
                            allowOppositeDirections={stop.allowOppositeDirections}
                            id={stop.id}
                            name={stop.name}
                            visible={(stop.outbound && this.state.direction == 'return') ? false : (stop.return && this.state.direction == 'outbound') ? false : true}
                            address={stop.address}
                            onClick={stop.selectable == false ? null : this.onMarkerClick}
                            icon={{
                                url: stop.id == MY_CUSTOM_ADDRESS && !window.selectAutoCompleteDestination ? pin_myAddress : stop.myAddressIsEqualStop && !window.selectAutoCompleteDestination == true ? pin_dropOff_myAddress : pin_dropOff /*this.props.tripReducer.stopsType == 'origin' ? pin_pickUp : pin_dropOff*/,
                                scaledSize: /*stop.id == MY_CUSTOM_ADDRESS ? new window.google.maps.Size(60, 50) :*/ new window.google.maps.Size(50, 70),
                            }}
                        />
                    )
                }
            })

        } else {
            return null;
        }
    }

    onInfoWindowStopNameOpen(props, e) {
        return (
            <div>
                <h3>{this.state.selectedPlace.name}</h3>
            </div>
        )
    }

    onInfoWindowOpen(props, e, distanceString) {
        const { t } = this.props
        let dataTripRequest = this.props.tripReducer.dataTripRequest;
        const DESTINATION_STRING = "destination";
        const isServiceLine = this.props.tripReducer.selectedService.serviceType == SERVICES_TYPE.regularService
        let stopNumber = isServiceLine ? (this.state.selectedPlace.outboundIndex != null
            ? (' - #' + (this.state.selectedPlace.outboundIndex + 1).toString())
            : (' - #' + (this.state.selectedPlace.returnIndex + 1).toString()))
            : ''
        const button = (
            <div style={{ textAlign: 'center' }}>
                <h3>{this.state.selectedPlace.name + ' ' + stopNumber}</h3>
                <p>{this.state.selectedPlace.address}</p>
                {distanceString != null ?
                    this.state.selectedPlace.id != MY_CUSTOM_ADDRESS ? <p>{distanceString != "" ? distanceString : null}</p> : null
                    :
                    this.state.selectedPlace.id != MY_CUSTOM_ADDRESS && (
                        <React.Fragment>
                            <div style={{ marginTop: 25, padding: 0 }}>
                                <Spinner centered={false} loading={true} />
                            </div>
                            <div style={{ marginTop: 0, padding: 5 }}>
                                <p>{t('home.loadingDistances')}</p>
                            </div>
                        </React.Fragment>
                    )
                }
                {this.props.tripReducer.stopsType != null &&
                    <div style={{ marginTop: 5, marginBottom: 5 }}>
                        <button
                            onClick={e => {
                                this.props.selectedStop(this.state.selectedPlace, this.props.tripReducer.stopsType, dataTripRequest, this.props.tripReducer.selectedService,
                                    this.state.selectedPlace.return ? 'return' : this.state.selectedPlace.outbound ? 'outbound' : '');
                                this.onClose(); //close infowindow
                                const serviceType = this.props.tripReducer.selectedService.serviceType
                                if (this.props.tripReducer.stopsType == 'origin') {
                                    if (!REGULAR_SERVICES_TYPES.includes(serviceType)) {
                                        if (!this.avoidSelectDestination(this.state.selectedPlace.id == "myCustomAddress"))
                                            this.props.setStops(this.props.tripReducer.selectedService, DESTINATION_STRING, this.props.tripReducer.selectedService.destinationZone.zoneStops, dataTripRequest)
                                    }
                                    else {
                                        if (serviceType == SERVICES_TYPE.regularService) {
                                            this.setState({
                                                onlyOutbound: this.state.selectedPlace.outbound ? true : null,
                                                onlyReturn: this.state.selectedPlace.return ? true : null,
                                                //direction: this.state.selectedPlace.return ? 'return' : 'outbound'
                                            })
                                            const { stopList } = this.props.tripReducer;
                                            let newStops = Object.assign([], stopList)
                                            if (!this.state.selectedPlace.allowOppositeDirections)
                                                newStops = newStops.splice(newStops.indexOf(newStops.find(i => i.id === this.state.selectedPlace.id)), newStops.length).filter(x => x.id != this.state.selectedPlace.id)
                                            if (newStops.length == 0) {
                                                store.dispatch(alertActions.warning(i18next.t('home.stopEmpty')))
                                            }
                                            this.props.setStops(this.props.tripReducer.selectedService, DESTINATION_STRING, newStops, dataTripRequest)
                                        } else {
                                            this.props.getRegularLinePossibleDropoffs(this.props.tripReducer.selectedService, DESTINATION_STRING, dataTripRequest, this.state.selectedPlace)
                                        }
                                    }
                                }
                            }}
                        >
                            {t('home.select')}
                        </button>
                    </div>
                }
            </div>
        );
        ReactDOM.render(
            React.Children.only(button),
            document.getElementById("iwc")
        );
    }

    avoidSelectDestination(hasSelectedMyCustomAddress) {
        const serviceSelected = this.props.tripReducer.selectedService
        return (!hasSelectedMyCustomAddress && serviceSelected?.destinationZone?.zoneType == zoneStopTypes.MixedOriginOrDestination)
    }

    render() {
        const { t, customerReducer, tripReducer } = this.props
        if (customerReducer.currentUser == undefined) {
            return <Spinner
                loading={true}
            />
        }
        return (
            <React.Fragment>
                <Grid container spacing={1} style={{ padding: 20 }} >
                    {this.props.tripReducer.trip == null ?
                        <RequestTripForm
                            {...this.props}
                            setMarkers={(markers) => this.setState({ markers: markers })}
                            setBounds={(bounds) => this.setState({ bounds: bounds })}
                            editable={tripReducer.isModifyTrip ? false : !tripReducer.showStops ? true : true}
                            ref={this.child}
                        />
                        :
                        <ResumeTrip
                            {...this.props}
                        />
                    }

                    <Grid item xs={12} md/*={8}*/ style={{ position: 'relative', height: '80vh', width: '100%' }}> {/*sm={12}*/}
                        {tripReducer.hasSelectedOriginStop && tripReducer.showStops ?
                            <Grid item md={12} style={{ padding: 5 }}>
                                <h3>{t('home.originSelected')} : {tripReducer.selectedOriginStop.name} <CancelIcon style={{ cursor: 'pointer' }} onClick={() => this.clearSelectedOriginStop()} /></h3>
                            </Grid>
                            : null}
                        {this.state.currentPosition && tripReducer.trip == null ?
                            <Map
                                ref={ref => { this.map = ref; }}
                                style={{ height: '80vh', width: '100%' }}
                                google={this.props.google}
                                zoom={10}
                                initialCenter={{
                                    lat: this.state.currentPosition.lat,
                                    lng: this.state.currentPosition.lng
                                }}
                                onClick={(mapProps, map, event) => {
                                    if (this.state.hideOriginDestination) {
                                        this.onClickZone()
                                    }
                                }}
                                streetViewControl={false}
                                fullscreenControl={false}
                                bounds={this.state.bounds}
                            >
                                {this.showMarkers()}
                                {this.showStopsMarkers(this.map)}
                                {this.checkServiceLine()}
                                {this.props.areasReducer.arrayGeofencing != null && this.props.areasReducer.arrayGeofencing.length > 0 ?
                                    this.props.areasReducer.arrayGeofencing.map((zone, index) => {
                                        return (
                                            <Polygon
                                                key={index}
                                                paths={zone.points}
                                                onClick={(mapProps, map, event) => {
                                                    if (zone.pickupAndDropOffAutoComplete) {
                                                        if (zone.stops.length >= 2) {
                                                            this.setOriginAndDestination(zone.id, zone.name, zone.stops[0].point)
                                                            window.selectAutoCompleteOrigin = true
                                                            window.selectAutoCompleteDestination = true
                                                        } else {
                                                            store.dispatch(alertActions.error(t('home.zoneEmpty')))
                                                        }
                                                    }
                                                }}
                                                editable={false}
                                                options={{
                                                    strokeColor: !zone.pickupAndDropOffAutoComplete ? 'rgba(0, 0, 255, 0.9)' : 'rgba(153, 158, 224, 0.9)',
                                                    strokeWeight: 0.5,
                                                    fillColor: !zone.pickupAndDropOffAutoComplete ? 'rgba(0, 0, 255, 0.8)' : 'rgba(153, 158, 224, 0.8)',
                                                    fillOpacity: 0.35
                                                }}
                                            />
                                        )
                                    })
                                    : null}
                                <InfoWindow
                                    ref={ref => { this.showStopName = ref }}
                                    marker={this.state.activeMarker}
                                    visible={this.state.showStopName}
                                    onClose={() => this.setState({ showStopName: false })}
                                    onOpen={e => {
                                        this.onInfoWindowStopNameOpen(this.props, e);
                                    }}
                                >
                                    <div id="iwc2" />
                                </InfoWindow>
                                <InfoWindow
                                    ref={ref => { this.showingInfoWindow = ref }}
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClose={this.onClose}
                                    onOpen={e => {
                                        let distanceString = "";
                                        if (tripReducer.stopsType != null) {
                                            let originPickUp = tripReducer.stopsType == 'origin' ? [tripReducer.originalData.origin.position.lat, tripReducer.originalData.origin.position.lng] : [tripReducer.originalData.destination.position.lat, tripReducer.originalData.destination.position.lng];
                                            let destination = [this.state.selectedPlace.position.lat, this.state.selectedPlace.position.lng]
                                            this.onInfoWindowOpen(this.props, e, null);
                                            if (!window.selectAutoCompleteOrigin || !window.selectAutoCompleteDestination) {
                                                calculateTimePoints(originPickUp, destination, window.google.maps.TravelMode.WALKING)
                                                    .then(
                                                        result => {
                                                            distanceString = distanceString + t('home.distance') + ': ' + result[0].distance.text + " / " + t('home.timeToDestination') + ': ' + result[0].duration.text
                                                            this.onInfoWindowOpen(this.props, e, distanceString);
                                                        },
                                                        error => {
                                                            this.onInfoWindowOpen(this.props, e, distanceString);
                                                        }
                                                    )
                                                    .catch(error => {
                                                        this.onInfoWindowOpen(this.props, e, distanceString);
                                                    })
                                            } else {
                                                this.onInfoWindowOpen(this.props, e, distanceString);
                                            }

                                        } else {
                                            this.onInfoWindowOpen(this.props, e, distanceString);
                                        }
                                    }}
                                >
                                    <div id="iwc" />
                                </InfoWindow>
                            </Map>
                            : tripReducer.trip != null || tripReducer.isSubmitting ?
                                <TripMap {...this.props} />
                                :
                                <Spinner loading={true} />
                        }
                    </Grid>
                    <ModalSelectPosibleSchedule
                        open={tripReducer.modalSelectScheduledPickUp ?? false}
                        expeditions={tripReducer.posibleSchedule}
                        tripReducer={tripReducer}
                        insert={this.props.requestTrip}
                        closeModalSelectScheduledPickUp={this.props.closeModalSelectScheduledPickUp}
                    />
                </Grid>
            </React.Fragment >
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (stringConstants.API_Key_Google),
})(withTheme(HomeView));