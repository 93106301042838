import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import { factory } from '../helpers/factory';
import firebase from 'firebase'
import i18next from 'i18next';

var firebaseConfig = {
    apiKey: stringConstants.ID_Aplication
};

firebase.initializeApp(firebaseConfig)

export const userService = {
    login,
    logout,
    getCustomerProfile,
    registerCustomer,
    sendPasswordResetEmail,
    sendVerifyEmail,
    getTokenId,
    forgotPassword,
    refreshToken,
    updateCustomerProfile,
    getTripsFromCustomer,
    removeMyProfile,
    fetchCoordsFromAddress,
    getPersonalInfo,
    getWithExpiry,
    setWithExpiry,
    canPerformTripFromProfileData,
    isCustomer,
    setLanguage,
    getRoleByClaims,
    customerAddress,
    isActive,
    modifyTrip,
    acceptModifyTrip
    //authorizationToSendEmail,
    //requestTrip,
    //getTripServices,
    // setTripToCancelledByUser,
    // setTripToAcceptedByUser,
    // setTripToRejectedByUser,
};

async function canPerformTripFromProfileData(customerId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/CanPerformTripFromProfileData?customerId=' + customerId, requestOptions).then(handleResponse);
}

function registerCustomer(email, password, firstName, lastName, document, gdprAccepted, birthDate, phoneNumber) {
    const args = factory.createCustomerRegistration(email, password, firstName, lastName, document, gdprAccepted, birthDate, phoneNumber)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(args)
    }
    return fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Registration/CustomerAppRegister', requestOptions)
        .then(handleResponse)
}

function getCustomerProfile() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/GetCustomerProfile', requestOptions).then(handleResponse);
}

async function updateCustomerProfile(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses) {
    const args = factory.createEditCustomer(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses);
    //console.log("args updateCustomerProfile => ", args);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(args)
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/Customers/v1/Customers/UpdateProfile', requestOptions)
        .then(handleResponse)
        .then(
            response => {
                return response;
            },
        );
}

function getTokenId() {
    return firebase.auth().currentUser.getIdTokenResult().then(
        data => {
            var diff = Math.abs(new Date(data.expirationTime) - new Date(data.authTime));
            setWithExpiry('token_customer', data.token, diff)
            return data
        }
    )
}

function logout() {
    return firebase.auth().signOut().then(
        data => {
            return data;
        }
    )
}

async function forgotPassword(email) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    let isCustomer = await fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/IsCustomer?email=' + encodeURIComponent(email), requestOptions).then(handleResponse);
    if (isCustomer == true) {
        // return firebase.auth().sendPasswordResetEmail(email).then(
        //     data => {
        //         return data;
        //     }
        // )
        let lang = i18next.language;
        await this.sendPasswordResetEmail(email, lang);
        return 'ok'
    } else {
        return 'error';
    }

}

function refreshToken() {
    const token = getWithExpiry('refreshToken_customer');
    const args = `grant_type=refresh_token&refresh_token=${token}`;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: args
    }
    return fetch(stringConstants.URL_Refresh_Token, requestOptions)
        .then(handleResponse)
        .then(data => {
            setWithExpiry('token_customer', data.access_token, data.expires_in)
            // store token details and jwt token in local storage to keep user logged in between page refreshes
            return token;
        })
}

function login(loginId, password) {
    return firebase.auth().signInWithEmailAndPassword(loginId, password).then(
        data => {
            return data;
        }
    )
}

async function sendVerifyEmail(email, lang) {
    const requestOptions = {
        method: 'GET',
    };
    let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
    return await fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Mailing/SendVerifyEmail?email=' + encodeURIComponent(email) + '&lang=' + lang + '&urlRedirect=' + url, requestOptions).then(handleResponse);
}

async function sendPasswordResetEmail(email, lang) {
    const requestOptions = {
        method: 'GET',
    };
    let url = window.location.origin + process.env.REACT_APP_LOGIN_PAGE
    return await fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Mailing/SendResetPasswordEmail?email=' + encodeURIComponent(email) + '&lang=' + lang + '&urlRedirect=' + url, requestOptions).then(handleResponse);
}

async function removeMyProfile() {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/Remove', requestOptions)
        .then(
            response => {
                return response.status;
            },
        );
}

function fetchCoordsFromAddress(address) { //not used, desactivat
    return fetch(stringConstants.URL_API_GEOCODE_GOOGLE + address + "&key=" + stringConstants.API_Key_Google)
        .then(handleResponse)
        .then(
            response => {
                return response;
            }
        )
}

async function getTripsFromCustomer() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/Services/v1/Trips/GetTripsFromCustomer', requestOptions).then(handleResponse);
}

async function getPersonalInfo() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/GDPR/GDPRPetition', requestOptions).then(handleResponse);
}

function customerAddress() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    let lng = i18next.language
    return fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/customerAddresses?lang=' + lng, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || text || response.status;
            return Promise.reject(error);
        }
        return data;
    });
}

async function isCustomer(email) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    let isCustomer = await fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/IsCustomer?email=' + encodeURIComponent(email), requestOptions).then(handleResponse);
    //console.log(`${email} isCustomer in userService => ${isCustomer}`);
    return isCustomer;
}

async function setLanguage(lang) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/customers/v1/Customers/SetLanguage?lang=' + lang, requestOptions)
        .then(
            response => {
                //console.log('setLanguage response => ', response);
                return response.status;
            },
        );
}

async function getRoleByClaims() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/opusers/v1/OpUsers/GetRoleFromClaims', requestOptions).then(handleResponse);
}

async function isActive(email) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/Customers/v1/Customers/IsCustomerActive?email=' + encodeURIComponent(email), requestOptions).then(handleResponse);
}

async function modifyTrip(origin, destination, numPassengers, pickupStartTime, customerName, hasWheelChair, /*babyCart,*/ luggage, serviceId, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf = false, relatedCustomer = null, passengerId) {
    const args = factory.createModifyTripRequest(origin, destination, numPassengers, pickupStartTime, customerName, hasWheelChair, /*babyCart,*/ luggage, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, passengerId);
    //console.log("trip modified sent => ", args);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        },
        body: JSON.stringify(args)
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/Services/v1/Trips/ModifyTrip?serviceId=' + serviceId, requestOptions)
        .then(handleResponse)
        .then(
            response => {
                //console.log("response (modifyTrip) => ", response);
                return response;
            }
        )
}

async function acceptModifyTrip(newTripId, oldTripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    //oldTripId = "aaaaaaaaaaaaaaaaaaaaaaaa"
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/Trips/AcceptModifyTrip?newTripId=' + newTripId + '&oldTripId=' + oldTripId, requestOptions)
        .then(
            response => {
                //console.log('resposta acceptModifyTrip => ', response);
                return response.status
            }
        )
    // NotFound
    // TripNotProcessed
    // StatusNotAllowed
}

function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    var date = new Date();
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    const now = new Date(now_utc)
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

function setWithExpiry(key, value, ttl) {
    var date = new Date();
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    const now = new Date(now_utc)
    ttl = ttl * 1000;
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
    //sessionStorage.setItem(key, JSON.stringify(item));
}

// function authorizationToSendEmail(email, password) {
//     firebase.auth().signInWithEmailAndPassword(email, password).then(
//         data => {
//             firebase.auth().currentUser.sendEmailVerification()
//             firebase.auth().signOut()
//         }
//     )
// }

// async function getTripServices(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, customerId, customerDateOfBirth, serviceName) {
//     const args = factory.createTripRequest(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, customerId, customerDateOfBirth, serviceName);
//     //console.log("getTripServices sent => ", args);
//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': authHeader(),
//         },
//         body: JSON.stringify(args)
//     };
//     return await fetch(stringConstants.URL_ApiGateway + '/api/Services/v1/Trips/GetTripServices', requestOptions)
//         .then(handleResponse)
//         .then(
//             response => {
//                 //console.log("response (getTripServices) => ", response);
//                 return response;
//             }
//         )
// }

// async function requestTrip(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, serviceId, customerDateOfBirth, serviceName) {
//     const args = factory.createTripRequest(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, serviceId, customerDateOfBirth, serviceName);
//     //console.log("trip sent => ", args);
//     const requestOptions = {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': authHeader(),
//         },
//         body: JSON.stringify(args)
//     };
//     return await fetch(stringConstants.URL_ApiGateway + '/api/Services/v1/Trips/RequestTrip?serviceId=' + serviceId, requestOptions)
//         .then(handleResponse)
//         .then(
//             response => {
//                 //console.log("response (trip) => ", response);
//                 return response;
//             }
//         )
// }

// async function setTripToCancelledByUser(tripId) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': authHeader()
//         }
//     };
//     return await fetch(stringConstants.URL_ApiGateway + '/api//services/v1/TripStatus/SetTripToCancelledByUser?id=' + tripId, requestOptions)
//         .then(
//             response => {
//                 //console.log('resposta setTripToCancelledByUser => ', response.status);
//                 return response.status
//             }
//         );
// }

// async function setTripToAcceptedByUser(tripId) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': authHeader()
//         }
//     };
//     return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToAcceptedByUser?id=' + tripId, requestOptions)
//         .then(
//             response => {
//                 //console.log('resposta setTripToAcceptedByUser => ', response.status);
//                 return response.status
//             }
//         );
// }

// async function setTripToRejectedByUser(tripId) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': authHeader()
//         }
//     };
//     return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToRejectedByUser?id=' + tripId, requestOptions)
//         .then(
//             response => {
//                 //console.log('resposta setTripToRejectedByUser => ', response.status);
//                 return response.status
//             }
//         );
// }