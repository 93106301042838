import React from 'react';
import { Grid, Button, Dialog, DialogContent } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Spinner from '../../shared/spinner';
import moment from 'moment';
import WaitingScreen from '../../custom/backdrop';
import UserTariffTypology from '../userTariff';
import { TripStatus } from '../../../constants/types';
class ResumeTrip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originAddress: '',
            destinationAddress: '',
            pickupTime: '',
            dropOffTime: '',
            resolve: false,
            clickInfoPrice: false
        }
        this.handleClickInfo = this.handleClickInfo.bind(this)
    }

    componentDidMount() {
        this.coordsToAddress();
    }

    componentWillUnmount() {
        const { trip, showResume } = this.props.tripReducer
        if (trip != null && showResume == false) {
            if (trip.status == TripStatus.Available) {
                this.props.setTripToRejectedByUser(trip.tripId)
                //console.log("setTripToRejectedByUser");
            }
        }
    }

    async coordsToAddress() {
        let originAddress = this.props.tripReducer.trip.pickUpLocation.address;
        let destinationAddress = this.props.tripReducer.trip.dropOffLocation.address;
        var pickupTimeUTC = moment.utc(this.props.tripReducer.trip.schedulePickUpTime).toDate();
        var pickupTime = moment(pickupTimeUTC).local().format('DD-MM-YYYY HH:mm');
        var dropOffTimeUTC = moment.utc(this.props.tripReducer.trip.scheduleDropOffTime).toDate();
        var dropOffTime = moment(dropOffTimeUTC).local().format('DD-MM-YYYY HH:mm');
        this.setState({
            originAddress,
            destinationAddress,
            pickupTime,
            dropOffTime,
            resolve: true
        })
    }

    acceptTrip = (tripId) => {
        if (this.props.tripReducer.isModifyTrip == true) {
            let newTripId = tripId;
            let oldTripId = this.props.tripReducer.modifyTripData.tripId;
            //console.log("newTripId => ", tripId);
            //console.log("oldTripId => ", oldTripId)
            this.props.acceptModifyTrip(newTripId, oldTripId);
        } else {
            this.props.setTripToAcceptedByUser(tripId);
        }
    }

    rejectTrip = (tripId) => {
        this.props.setTripToRejectedByUser(tripId);
    }

    handleClickInfo() {
        this.setState({
            clickInfoPrice: !this.state.clickInfoPrice
        })
    }

    onRepeatTrip() {
        const { trip } = this.props.tripReducer
        const newTrip = {
            ...trip,
            pickUpLocation: trip.dropOffLocation,
            dropOffLocation: trip.pickUpLocation,
        }

        let relatedCustomer = null
        for (let i = 0; i < this.props.customerReducer.relatedCustomers.length; i++) {
            if (this.props.customerReducer.relatedCustomers[i].id === newTrip.relatedCustomerId) {
                relatedCustomer = {
                    id: newTrip.relatedCustomerId,
                    name: newTrip.relatedCustomerName,
                    dateOfBirth: this.props.customerReducer.relatedCustomers[i].birthDate
                }
            }
        }
        const newCurrentTrip = { ...newTrip, relatedCustomer }

        this.props.repeatTrip(newCurrentTrip)
        this.props.cleanTrip()
    }


    render() {
        const { t } = this.props
        //console.log("this.props.tripReducer.trip => ", this.props.tripReducer.trip)

        const Footer = () => {
            const displayRepeatButton = [TripStatus.AcceptedByCustomerService, TripStatus.AcceptedByUser,
            TripStatus.AcceptedByIVR, TripStatus.AcceptedByThirdParty, TripStatus.AcceptedByDriver].includes(this.props.tripReducer.trip.status)

            return (
                <footer>
                    {
                        this.props.tripReducer.showResume == false ?
                            (
                                <>
                                    <div style={{ padding: 5, marginTop: 10, marginBottom: 20 }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            style={{ width: 300 }}
                                            onClick={() => this.acceptTrip(this.props.tripReducer.trip.tripId)}
                                            size="medium">
                                            {t('form.resumeService.accept')}
                                        </Button>
                                    </div>
                                    <div style={{ padding: 5, marginTop: 10, marginBottom: 20 }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                            style={{ width: 300 }}
                                            onClick={() => this.rejectTrip(this.props.tripReducer.trip.tripId)}
                                            size="medium">
                                            {t('form.resumeService.reject')}
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {
                                        //TODO: Descomentar botón cuando se quiera 
                                        // displayRepeatButton && (
                                        //     <div style={{ padding: 5, marginTop: 10, marginBottom: 20 }}>
                                        //         <Button
                                        //             variant='contained'
                                        //             color='primary'
                                        //             type='submit'
                                        //             style={{ width: 300 }}
                                        //             onClick={() => this.onRepeatTrip()}
                                        //             size='medium'
                                        //         >
                                        //             {t('form.resumeService.repeat')}
                                        //         </Button>
                                        //     </div>
                                        // )
                                    }
                                </>

                            )
                    }
                </footer>
            )
        }

        if (this.state.resolve) {
            return (
                <Grid style={styles.gridContainer}>
                    <div id='resume' style={styles.divContainer}>
                        <h2>{t('form.resumeService.tripResume')}</h2>
                        <h3>{t('form.resumeService.origin')}</h3>
                        <p>
                            {this.props.tripReducer.selectedOriginStop != null ? this.props.tripReducer.selectedOriginStop?.name + ' / ' : '' || ''}
                            {this.state.originAddress}
                        </p>
                        <h3>{t('form.resumeService.destination')}</h3>
                        <p>
                            {this.props.tripReducer.selectedDestinationStop != null ? this.props.tripReducer.selectedDestinationStop?.name + ' / ' : '' || ''}
                            {this.state.destinationAddress}
                        </p>
                        <h3>{t('form.resumeService.dateTime')}</h3>
                        <p>{t('form.resumeService.pickUpTime') + ': ' + this.state.pickupTime}</p>
                        <p>{t('form.resumeService.dropOffTime') + ': ' + this.state.dropOffTime}</p>
                        <h3>{t('form.resumeService.additional')}</h3>
                        <p>{t('form.resumeService.numPassengers') + ': ' + this.props.tripReducer.trip.numPassengers}</p>
                        <p>{t('form.resumeService.price') + ': ' + this.props.tripReducer.trip.price.amount + '€'}
                            {
                                this.props.tripReducer.trip.price.userTariff ?
                                    <InfoIcon onMouseEnter={this.handleClickInfo} />
                                    : null
                            }
                        </p>
                        <p>{this.props.tripReducer.trip.hasWheelChair == true ? t('form.resumeService.requiredAdaptedVehicle') : null}</p>
                        <p>{t('form.resumeService.numLuggage') + ': ' + this.props.tripReducer.trip.luggage}</p>
                        <p></p>
                        <p>{t('form.resumeService.serviceOfferedBy') + ': ' + this.props.tripReducer.trip.serviceName}</p>
                    </div>
                    <Footer />

                    <Dialog
                        open={this.state.clickInfoPrice}
                        maxWidth={'md'}
                        fullWidth
                        onClose={this.handleClickInfo}
                    >
                        <DialogContent>
                            <UserTariffTypology
                                tariff={this.props.tripReducer.trip.userTariff}
                                t={t}
                            //canEdit={false}
                            />
                        </DialogContent>
                    </Dialog>
                    <WaitingScreen
                        message={t('form.resumeService.processingRequest')}
                        open={this.props.tripReducer.isSubmitting == true ? true : false}
                    />
                </Grid >
            )
        } else {
            return (
                <Spinner loading={true} />
            )
        }
    }
}

export default ResumeTrip;

const styles = {
    gridContainer: {
        borderColor: 'green',
        borderWidth: 0,
        borderStyle: 'dashed',
        borderRadius: 20
    },
    divContainer: {
        backgroundColor: 'white',
        paddingLeft: 15,
        paddingRight: 5,
        width: 300,
    }
}