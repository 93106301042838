import React from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import { history } from './helpers/history';
import PrivateRoute from './PrivateRoute';
import Home from './components/home';
import Login from './components/login';
import Register from './components/register';
import Profile from './components/profile';
import ResetPassword from './components/resetPassword';
import Bookings from './components/bookings';
import i18next from 'i18next';

class Root extends React.Component {

    componentDidMount() {
        document.title = i18next.t('dashboard.title')
    }

    render() {
        return (
            <Router history={history} basename={'/'}>
                <Switch>
                    {/* <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}></Route> */}
                    <Route path={`/login`} component={Login}></Route>
                    <Route path={`/register`} component={Register}></Route>
                    <Route path={`/resetPassword`} component={ResetPassword}></Route>
                    <PrivateRoute path={`/home`} component={Home}></PrivateRoute>
                    <PrivateRoute path={`/profile`} component={Profile}></PrivateRoute>
                    <PrivateRoute path={`/bookings`} component={Bookings}></PrivateRoute>
                    <Route path="/" render={() => <Redirect to={`/home`} />} />
                </Switch>
            </Router>
        )
    }
}




export default Root