import { combineReducers } from 'redux';
import { authenticationReducer } from './authentication_reducer';
import { alertReducer } from './alert_reducer';
import { customerReducer } from './customer_reducer';
import { tripReducer } from './trip_reducer';
import { areasReducer } from './areas_reducer';
import { trackingReducer } from './tracking_reducer';

const reducer = combineReducers({
    authenticationReducer,
    alertReducer,
    customerReducer,
    tripReducer,
    areasReducer,
    trackingReducer
});

export default reducer;