const geofencing = {
    _isInPolygon,
    transformToJsonAreas,
    generateGeoJsonArray,
    generatePolygon,
    distanceBetween2Points
}

function _isInPolygon(point, polygonArray) {

    let x = point.latitude
    let y = point.longitude

    let inside = false
    for (let i = 0, j = polygonArray.length - 1; i < polygonArray.length; j = i++) {
        let xLat = polygonArray[i].lat
        let yLat = polygonArray[i].lon
        let xLon = polygonArray[j].lat
        let yLon = polygonArray[j].lon

        let intersect = ((yLat > y) !== (yLon > y)) && (x < (xLon - xLat) * (y - yLat) / (yLon - yLat) + xLat)
        if (intersect) inside = !inside
    }
    return inside
}

function transformToJsonAreas(array) {
    let transformedArray = [];
    array.map((point, index) => {
        //console.log("point => ", point);
        let pointTransformed = [point.lon, point.lat];
        transformedArray.push(pointTransformed);
    })
    return transformedArray;
}

function generateGeoJsonArray(arrayAreas) {
    let arrayZonesGeoJson = [];

    let zoneJson = {
        "type": "FeatureCollection",
        "name": "Layer0",
        "crs": {
            "type": "name",
            "properties":
            {
                "name":
                    "urn:ogc:def:crs:OGC:1.3:CRS84"
            }
        },
        "features": [
            {
                "type": "Feature", "properties": { "Name": "Spain", "description": null }, "geometry": {
                    "type": "MultiPolygon", "coordinates": [[[
                        //coordenades de cada zona
                    ]]]
                }
            }
        ]
    };
    arrayZonesGeoJson.push(zoneJson);
}

function generatePolygon(array) {
    let polygonArray = [];
    array.map((point, index) => {
        let pointTransformed = { lat: Number(point.lat), lng: Number(point.lon) };
        //{{ lat: 41.48264391182122, lng: 2.2917828334986856 }}
        polygonArray.push(pointTransformed);
    })
    return polygonArray;
}

function distanceBetween2Points(lat1, lon1, lat2, lon2, unit) {
    unit = 'K'
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") { dist = dist * 1.609344 } //K = (km)
        if (unit == "N") { dist = dist * 0.8684 }
        return dist;
    }
}

export default geofencing;