
import i18next from 'i18next';

const utils = {
    validateEmail,
    validateName,
    validateLastName,
    validateID,
    validatePassword,
    validatePhone,
    spainIDType,
    required,
    calculateAge,
    validateRules
}

let NAME_REGEX = /^[a-z'A-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
//let LASTNAME_REGEX = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/; //OLD-REGEX
let LASTNAME_REGEX = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
let EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
let PASSPORT_REGEX = /^[A-Za-z]{3}[0-9]{6}[A-Za-z]?$/i;
let DNI_REGEX = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
let NIE_REGEX = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
let PHONE_REGEX = /^(\+34|0034|34)?[ -]*(6|7|9)[ -]*([0-9][ -]*){8}$/;

function validateEmail(email) {
    const regex = EMAIL_REGEX;
    let result = regex.test(String(email).toLowerCase());
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.email') }
}

function validateName(name) {
    const regex = NAME_REGEX;
    let result = regex.test(String(name));
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.name') }
}

function validateLastName(lastName) {
    const regex = LASTNAME_REGEX;
    let result = regex.test(String(lastName));
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.lastName') }
}

function validatePassword(password) {
    const regex = PASSWORD_REGEX;
    let result = regex.test(String(password));
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.password') }
}

function validatePhone(phone) {
    const regex = PHONE_REGEX;
    let result = regex.test(String(phone));
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.phone') }
}

function validateDNI(dni, isNIE) {
    let numero = dni.substr(0, dni.length - 1);
    let letr = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    let letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra !== letr.toUpperCase()) {
        if (isNIE == null) {
            //console.log('DNI erroneo, la letra del NIF no se corresponde');
        }
        else {
            //console.log('NIF erroneo, la letra del NIF no se corresponde');
        }
        return { result: false, message: i18next.t('validations.register.document') }
    } else {
        if (isNIE == null) {
            //console.log('DNI correcto');
        } else {
            //console.log('NIE correcto');
        }
        return { result: true, message: '' }

    }
}

function validatePassport(passport) {
    //console.log("Pasaporte " + passport+". No se puede validar ya que no hay métodos.")
    const regex = PASSPORT_REGEX;
    let result = regex.test(String(passport));
    //console.log("result passport => ", result);
    return result ? { result, message: '' } : { result, message: i18next.t('validations.register.passport') };
    //return { result: true, message: '' }
}

function required(value, callback = null) {
    if (!value && value !== null && value.length === 0) {
        return { result: false, message: i18next.t('validations.required') }
    } else {
        if (callback) {
            return callback
        }
        return { result: true, message: '' }
    }
}

function validateRules(bool) {
    if (bool == false) {
        return { result: false, message: i18next.t('validations.required') }
    } else {
        return { result: true, message: '' }
    }
}

function calculateAge(birthDate, minAge) {
    const MAX_AGE = 120;
    var from = birthDate.split("-");
    var birthdateTimeStamp = new Date(from[0], from[1] - 1, from[2]);
    var cur = new Date();
    var diff = cur - birthdateTimeStamp;
    // This is the difference in milliseconds
    var currentAge = Math.floor(diff / 31557600000);
    // Divide by 1000*60*60*24*365.25
    // if (currentAge > MAX_AGE) {
    //     return { result: false, message: 'Selecciona una fecha correcta'/*i18next.t('validations.register.birthDate')*/ }
    // }
    return currentAge < minAge ? { result: false, message: i18next.t('validations.register.birthDate') } : { result: true, message: '' }

}

function validateNIE(nie) {
    // Change the initial letter for the corresponding number and validate as DNI
    var nie_prefix = nie.charAt(0);
    switch (nie_prefix) {
        case 'X': nie_prefix = 0; break;
        case 'Y': nie_prefix = 1; break;
        case 'Z': nie_prefix = 2; break;
    }
    let result = validateDNI(nie_prefix + nie.substr(1), true);
    return result
}

function spainIDType(str) {
    if (str.match(DNI_REGEX)) {
        return 'DNI';
    }
    if (str.match(PASSPORT_REGEX)) { //pendent
        return 'PASSPORT';
    }
    if (str.match(NIE_REGEX)) {
        return 'NIE';
    }
    return 'INVALID';
}

function validateID(id) {
    let type = spainIDType(id);
    //console.log("ID Type => ", type);
    switch (type) {
        case 'DNI':
            //'we\'ll call validateDNI() and return the result'
            return validateDNI(id);
        case 'PASSPORT':
            //'we\'ll call validatePassport() and return the result'
            return validatePassport(id);
        case 'NIE':
            //'we\'ll call validateNIE() and return the result'
            return validateNIE(id);
        default:
            return { result: false, message: i18next.t('validations.register.document') };
    }
}


export default utils;