export const getOriginRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.pickUpLocation.desc.description
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.pickUpLocation.address
    }

    return ''
}

export const getDestinationRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.dropOffLocation.desc.description
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.dropOffLocation.address
    }

    return ''
}

export const getPickUpTimeRequestTrip = (tripReducer) => {
    if (tripReducer.modifyTripData != null) {
        return new Date(tripReducer.modifyTripData.requestPickUpStartTime)
    }

    return null
}

export const getPassengersRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.numPassengers
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.numPassengers
    }

    return 1
}

export const getLuggageRequestTrip = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.luggage
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.luggage
    }

    return 0
}

export const getWheelChairRequestTrip = (tripReducer, customerReducer) => {
    if(tripReducer.repeatDataObject == null){
        return customerReducer.currentUser.hasWheelChair
    }
    
    const { hasWheelChair } = tripReducer.repeatDataObject
    return  hasWheelChair 
}

export const getIsOnRelatedCustomerBehalf = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.isOnRelatedCustomerBehalf
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.isOnRelatedCustomerBehalf
    }

    return false
}

export const getRelatedCustomer = (tripReducer) => {
    if (tripReducer.repeatDataObject != null) {
        return tripReducer.repeatDataObject.relatedCustomer
    }

    if (tripReducer.modifyTripData != null) {
        return tripReducer.modifyTripData.relatedCustomer
    }

    return null
}