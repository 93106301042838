import { authHeader } from '../helpers/authHeader';
import stringConstants from '../constants/strings';
import { factory } from '../helpers/factory';
import i18next from 'i18next';


export const tripsService = {
    customerCanPerformTrip,
    requestTrip,
    getTripServices,
    setTripToAcceptedByUser,
    setTripToCancelledByUser,
    setTripToRejectedByUser,
    getDestinationZones,
    getWeekDays,
    getTripStatuses
};

async function requestTrip(trip, serviceId) {
    //const args = factory.createTripRequest(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, serviceId, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, direction, idStopP, idStopD);
    //console.log("trip sent => ", args);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader(),
        },
        body: JSON.stringify({ ...trip, isWebCustomersRequest: true })
    };
    const URL = `${stringConstants.URL_ApiGateway}/api/Services/v1/Trips/RequestTrip?serviceId=${serviceId}`
    return await fetch(URL, requestOptions)
        .then(handleResponse)
        .then(
            response => {
                return response;
            }
        )
}

async function getTripServices(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, customerId, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf = false, relatedCustomer = null) {
    const args = factory.createTripRequest(origin, originAddress, destination, destinationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, customerId, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer);
    //console.log("getTripServices sent => ", args);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader(),
        },
        body: JSON.stringify(args)
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/Services/v1/Trips/GetTripServices', requestOptions)
        .then(handleResponse)
        .then(
            response => {
                //console.log("response (getTripServices) => ", response);
                return response;
            }
        )
}

function getTripStatuses() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    }
    const lang = i18next.language
    return fetch(
        stringConstants.URL_ApiGateway +
        '/services/v1/tripStatus/TripStatuses?lang=' +
        lang,
        requestOptions,
    ).then(handleResponse)
}

function customerCanPerformTrip(pickupStartTime, customerId) {
    let pickupStartTimeUTC = new Date(pickupStartTime).toUTCString();
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authHeader()
        }
    };
    return fetch(stringConstants.URL_ApiGateway + '/api/services/v1/Trips/CustomerCanPerformTrip?pickupStartTime=' + pickupStartTimeUTC + '&customerId=' + customerId, requestOptions).then(handleResponse);
}

async function setTripToCancelledByUser(tripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToCancelledByUser?id=' + tripId, requestOptions).then(handleResponse)
        .then(
            response => {
                //console.log('resposta setTripToCancelledByUser => ', response.status);
                return response//.status
            }
        );
}

async function setTripToAcceptedByUser(tripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToAcceptedByUser?id=' + tripId, requestOptions).then(handleResponse)
        .then(
            response => {
                //console.log('resposta setTripToAcceptedByUser => ', response.status);
                return response//.status
            }
        );
}

async function setTripToRejectedByUser(tripId) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/TripStatus/SetTripToRejectedByUser?id=' + tripId, requestOptions)
        .then(
            response => {
                //console.log('resposta setTripToRejectedByUser => ', response.status);
                return response.status
            }
        );
}

async function getDestinationZones(zoneId, lat, lon) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    return await fetch(stringConstants.URL_ApiGateway + '/api/services/v1/serviceZones/GetDestinationZones?zoneId=' + zoneId, requestOptions)
        .then(
            handleResponse
        );
}

async function getWeekDays() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    };
    const lang = i18next.language
    return await fetch(stringConstants.URL_ApiGateway + '/services/v1/services/weekDays?lang=' + lang, requestOptions)
        .then(
            handleResponse
        );
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || text || response.status;
            return Promise.reject(error);
        }
        return data;
    });
}