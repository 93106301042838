import i18next from 'i18next';
import moment from 'moment'
import store from '../redux/store';

export const factory = {
    createCustomerRegistration,
    createEditCustomer,
    createCustomer,
    createLogin,
    createTripRequest,
    createModifyTripRequest,
    formatDate,
    parseJwt
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


function createCustomer(name, surname1, surname2, identityDocumentCode, email, phoneNumber, isPMR, hasWheelChair, birthDate) {
    return {
        "name": name,
        "fullSurname": surname1 + ' ' + surname2,
        "surname1": surname1,
        "surname2": surname2,
        "identityDocumentCode": identityDocumentCode,
        "email": email,
        "phoneNumber": phoneNumber,
        "isPMR": isPMR,
        "hasWheelChair": hasWheelChair,
        "birthDate": birthDate
    }
}


function createCustomerRegistration(email, password, firstName, surname1, document, gdprAccepted, birthDate, phoneNumber) {
    return {
        "email": email,
        "password": password,
        "name": firstName,
        "surname1": surname1,
        "identityDocumentCode": document,
        "gdprAccepted": gdprAccepted,
        "birthDate": moment(birthDate),
        "phoneNumber": phoneNumber,
        "lang": "ca"
    }
}

function createEditCustomer(email, firstName, lastName, lastName2, phoneNumber, ID, isPMR, hasWheelChair, related, birthDate, addressPosition, favouriteAddresses) {
    return {
        "email": email,
        "name": firstName,
        "surname1": lastName,
        "surname2": lastName2,
        "birthDate": birthDate,//moment(birthDate).utc().format('YYYY-MM-DD'),
        "phoneNumber": phoneNumber,
        "identityDocumentCode": ID,
        "addressPosition": addressPosition,
        "isPMR": isPMR,
        "hasWheelChair": hasWheelChair,
        "relatedCustomers": related,
        "favouriteAddresses": favouriteAddresses
    }
}


function createLogin(loginId, password) {
    return {
        "email": loginId,
        "password": password,
        "returnSecureToken": true
    }
}

function createTripRequest(pickUpLocation, pickUpLocationAddress, dropOffLocation, dropOffLocationAddress, numPassengers, pickupStartTime, customerName, hasWheelChair, babyCart, luggage, serviceId, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, serviceLineDirection = null, idStopP, idStopD) {
    let lang = i18next.language;
    if (store.getState().customerReducer.currentUser != null) {
        lang = store.getState().customerReducer.currentUser.language;
    }
    return {
        'pickUpLocation': {
            'lat': pickUpLocation.position.lat, /*41.4894712,*/
            'lon': pickUpLocation.position.lng, /*2.29463,*/
            'address': pickUpLocationAddress,
            'id': idStopP?.replace('myCustomAddress', '')
        },
        'dropOffLocation': {
            'lat': dropOffLocation.position.lat, /*41.4894012,*/
            'lon': dropOffLocation.position.lng, /*2.29461,*/
            'address': dropOffLocationAddress,
            'id': idStopD?.replace('myCustomAddress', '')
        },
        'requestPickUpStartTime': moment(pickupStartTime).utc().format('YYYY-MM-DD HH:mm'),
        'numPassengers': numPassengers,
        'customerName': customerName,
        'hasWheelChair': hasWheelChair,
        'luggage': luggage,
        "serviceId": serviceId,
        "customerDateOfBirth": customerDateOfBirth,
        "serviceName": serviceName,
        "customerNotificationLanguage": lang,
        "isOnRelatedCustomerBehalf": isOnRelatedCustomerBehalf || false,
        "relatedCustomer": relatedCustomer || null,
        "serviceLineDirection": serviceLineDirection ? capitalizeFirstLetter(serviceLineDirection) : ''
    }
}

function createModifyTripRequest(pickUpLocation, dropOffLocation, numPassengers, pickupStartTime, customerName, hasWheelChair, /*babyCart,*/ luggage, pushToken, customerDateOfBirth, serviceName, isOnRelatedCustomerBehalf, relatedCustomer, passengerId) {
    let lang = i18next.language;
    if (store.getState().customerReducer.currentUser != null) {
        lang = store.getState().customerReducer.currentUser.language;
    }
    return {
        'pickUpLocation': pickUpLocation,
        'dropOffLocation': dropOffLocation,
        'requestPickUpStartTime': moment(pickupStartTime).utc().format('YYYY-MM-DD HH:mm'),
        'numPassengers': numPassengers,
        'customerName': customerName,
        'hasWheelChair': hasWheelChair,
        'luggage': luggage, //integer
        //"customerExpoPushToken": pushToken,
        "customerDateOfBirth": customerDateOfBirth,
        "serviceName": serviceName,
        "customerNotificationLanguage": lang,
        "isOnRelatedCustomerBehalf": isOnRelatedCustomerBehalf || false,
        "relatedCustomer": relatedCustomer || null,
        "passengerId": passengerId
    }
}

function formatDate(date, format) {
    var minDate = moment.utc("0001-01-01"); // minimum value as per UTC

    var receiveDate = moment(date);
    if (moment.utc(receiveDate).isAfter(minDate)) {
        return receiveDate.format(format)
    } else {
        return ""
    }
}