import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@material-ui/core"
import i18next from "i18next";
import moment from "moment";
import React from 'react';
import { useEffect, useState } from "react";
import Spinner from "../shared/spinner";
import { factory } from "../../helpers/factory";


const ModalSelectPosibleSchedule = ({ closeModalSelectScheduledPickUp, expeditions, tripReducer, open, insert }) => {

    const handleClickOption = (event) => {
        const value = event.target.value
        const optionSelected = expeditions.find(item => item.hour == value)
        const originStop = tripReducer.selectedOriginStop
        const destinationStop = tripReducer.selectedDestinationStop
        const hourSplitted = value.split(":")
        let pickUpTime = moment(tripReducer.dataTripRequest.pickupStartTime).set({ 'hours': hourSplitted[0], 'minutes': hourSplitted[1] })
        setRequestTrip(prevRequestTrip => ({
            ...tripReducer.dataTripRequest,
            pickUpStopId: originStop.id,
            dropOffStopId: destinationStop.id,
            regularLineId: optionSelected.regularLineId,
            expeditionId: optionSelected.expeditionId,
            pickupStartTime: pickUpTime.toDate()
        }))
        setValue(value)

    }

    const [value, setValue] = useState('')
    const [requestTrip, setRequestTrip] = useState(tripReducer.dataTripRequest)

    const onClickAcceptExpedition = () => {
        const service = tripReducer.selectedService
        let trip = factory.createTripRequest(
            requestTrip.origin, requestTrip.originAddress, requestTrip.destination, requestTrip.destinationAddress,
            requestTrip.numPassengers, requestTrip.pickupStartTime, requestTrip.customerName, requestTrip.hasWheelChair,
            requestTrip.babyCart, requestTrip.luggage, service.id, requestTrip.customerDateOfBirth, service.name,
            requestTrip.isOnRelatedCustomerBehalf, requestTrip.relatedCustomer, 'outbound', requestTrip.origin.id, requestTrip.destination.id
        )
        trip = {
            ...trip,
            pickUpStopId: requestTrip.pickUpStopId,
            dropOffStopId: requestTrip.dropOffStopId,
            regularLineId: requestTrip.regularLineId,
            expeditionId: requestTrip.expeditionId,
            pickupStartTime: requestTrip.pickupStartTime
        }
        insert(trip, service.id);

    }

    const onClickCancelExpedition = () => {
        closeModalSelectScheduledPickUp()
    }

    useEffect(() => {
        setValue('')
    }, [open])

    useEffect(() => {
        return () => {
            closeModalSelectScheduledPickUp()
        }
    }, [closeModalSelectScheduledPickUp])

    const requestTripObject = tripReducer.dataTripRequest

    return (
        <Dialog open={open} onClose={closeModalSelectScheduledPickUp}>
            <DialogTitle style={{ textAlign: 'center' }}>
                {i18next.t('form.modalSchedule.title')} {moment(requestTripObject?.pickupStartTime).format("HH:mm")}
            </DialogTitle>
            <DialogContent style={{ width: '100%', alignItems: 'center' }} >
                <TextField
                    fullWidth
                    value={value}
                    variant="outlined"
                    select
                    label={i18next.t('form.modalSchedule.availableTimes')}
                    onChange={(event) => handleClickOption(event)}
                >
                    {expeditions?.map(option =>
                        <MenuItem
                            value={option.hour}
                            key={option.hour}
                        >
                            {option.hour}
                        </MenuItem>
                    )}
                </TextField>
                <Spinner
                    loading={tripReducer.isSubmitting}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClickCancelExpedition()}
                    size="small"
                    disabled={tripReducer.isSubmitting}
                >
                    {i18next.t('form.modalSchedule.cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={value === '' || tripReducer.isSubmitting}
                    onClick={() => onClickAcceptExpedition()}
                    size="small"
                >
                    {i18next.t('form.modalSchedule.accept')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalSelectPosibleSchedule