import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'gray',// theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function AutocompleteRelatedRequest(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value ? props.value : null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  //const loaded = React.useRef(false);

  React.useEffect(() => {
    let active = true;
    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Autocomplete
      id={props.id}
      disabled={!props.editable}
      options={props.optionsDefault && props.optionsDefault.length > 0 ? options.concat(props.optionsDefault) : options}
      //style={{ width: props.width ? null : 300 }}
      fullWidth
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(options);
        setValue(newValue);
        props.setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          fullWidth
          error={props.error}
        />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <Grid container alignItems="center">
            <Grid item>
              {
                option.type == "relatedCustomer" && (
                  <PersonIcon className={classes.icon} />
                )
              }
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
