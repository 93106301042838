import React from 'react'
import GoogleMapReact from 'google-map-react';
import stringConstants from '../../../constants/strings';
//import { Grid } from '@material-ui/core';
import moment from 'moment';
import { trackingServices } from '../../../services/trackingServices';


class TripMap extends React.Component {

    constructor(props) {
        super()
        this.state = {
            markers: [],
        }
    }

    static defaultProps = {
        center: {
            lat: 41.390205,
            lng: 2.154007
        },
        zoom: 11
    };

    componentWillUnmount() {
        if (this.state.intervalId) {
            //console.log("desmontem el component i parem l'interval");
            clearInterval(this.state.intervalId);
            localStorage.removeItem('vehiclePosition');
        }
    }

    apiHasLoaded(map, maps) {
        const directionsService = new maps.DirectionsService();
        //const directionsDisplay = new maps.DirectionsRenderer();
        const { tripReducer, t } = this.props
        if (tripReducer.trip != null) {
            directionsService.route({
                origin: { lat: tripReducer.trip.pickUpLocation.lat, lng: tripReducer.trip.pickUpLocation.lon },
                destination: { lat: tripReducer.trip.dropOffLocation.lat, lng: tripReducer.trip.dropOffLocation.lon },
                travelMode: 'DRIVING'
            }, (response, status) => {
                if (status === 'OK') {
                    var latlngList = []
                    var points = response.routes[0].overview_path
                    points.forEach(element => {
                        latlngList.push(new maps.LatLng({ lat: element.lat(), lng: element.lng() }));
                    });
                    var markerStart = new maps.Marker({
                        map: map,
                        animation: maps.Animation.DROP,
                        icon: 'http://maps.google.com/mapfiles/kml/paddle/go.png',
                        position: points[0]
                    });
                    var markerEnd = new maps.Marker({
                        map: map,
                        animation: maps.Animation.DROP,
                        icon: 'http://maps.google.com/mapfiles/kml/paddle/grn-circle.png',
                        position: points[points.length - 1]
                    });
                    const trip = tripReducer.trip
                    //console.log("trip agafat => ", trip)
                    // if it´s accepted or onGoing, must show the vehicle
                    let intervalId = null;
                    if ([50, 51, 52, 53, 80, 110, 111].includes(trip.status)) {
                        //console.log("entro aqui");
                        trackingServices.getPositionForTrip(trip.vehicleId, trip.schedulePickUpTime, trip.scheduleDropOffTime).then(
                            position => {
                                //console.log("position received => ", position)
                                var url_available = process.env.PUBLIC_URL + "/content/img/bus_available.png"
                                localStorage.setItem('vehiclePosition', JSON.stringify(position))
                                var vehicle = new window.google.maps.Marker(
                                    {
                                        icon: {
                                            url: url_available,
                                            scaledSize: new maps.Size(50, 50)
                                        },
                                        position: { lat: position.latitude, lng: position.longitude },
                                        key: 'vehicle',
                                        map,
                                    }
                                )
                                const item = { 'id': 'vehicle', 'item': vehicle }
                                this.state.markers.push(item)
                            },
                            error => {
                                //console.log("error ** => ", error);
                                clearInterval(intervalId);
                                if (this.state.markers.find(x => x.id === 'vehicle') != null) {
                                    const marker = this.state.markers.find(x => x.id === 'vehicle').item;
                                    if (marker != null) {
                                        marker.setMap(null)
                                    }
                                }
                            }
                        )
                        intervalId = setInterval(() => this.refreshVehiclePosition(trip.vehicleId, trip.schedulePickUpTime, trip.scheduleDropOffTime, map), stringConstants.TIME_REFRESH_VEHICLE_POSITION)
                        this.setState({ intervalId: intervalId })
                    }
                    // else {
                    //     console.log("no entro a l'if => trip.status = ", trip.status);
                    // }
                    var infoWindowStart = new maps.InfoWindow({ pixelOffset: new window.google.maps.Size(0, -70) });
                    var infoWindowEnd = new maps.InfoWindow({ pixelOffset: new window.google.maps.Size(0, -70) });

                    markerStart.addListener('mouseover', showInfoStart);
                    markerEnd.addListener('mouseover', showInfoEnd);
                    markerStart.addListener('mouseout', () => {
                        setTimeout(() => {
                            infoWindowStart.close()
                        }, 100);
                    })
                    markerEnd.addListener('mouseout', () => {
                        setTimeout(() => {
                            infoWindowEnd.close()
                        }, 100);
                    });
                    function showInfoStart(event) {
                        var pickupTimeUTC = moment.utc(tripReducer.trip.schedulePickUpTime).toDate();
                        var pickupTime = moment(pickupTimeUTC).local().format('YYYY-MM-DD HH:mm');
                        var contentString = t('maps.pickUpTime') + pickupTime;
                        // Replace the info window's content and position.
                        infoWindowStart.setContent(contentString);
                        infoWindowStart.setPosition(event.latLng);

                        infoWindowStart.open(map);
                    }
                    function showInfoEnd(event) {
                        var dropOffTimeUTC = moment.utc(tripReducer.trip.scheduleDropOffTime).toDate();
                        var dropOffTime = moment(dropOffTimeUTC).local().format('YYYY-MM-DD HH:mm');
                        var contentString = t('maps.dropOffTime') + dropOffTime;
                        // Replace the info window's content and position.
                        infoWindowEnd.setContent(contentString);
                        infoWindowEnd.setPosition(event.latLng);

                        infoWindowEnd.open(map);
                    }
                    var bounds = new maps.LatLngBounds();
                    latlngList.forEach(function (n) {
                        bounds.extend(new maps.LatLng(n.lat(), n.lng()));
                    });
                    map.setCenter(bounds.getCenter());
                    map.fitBounds(bounds);
                    markerStart.setMap(map)
                    markerEnd.setMap(map)
                } else {
                    //window.alert('Directions request failed due to ' + status);
                    window.alert(t('maps.errorOccurs'));
                }
            });
        }
    }

    refreshVehiclePosition(vehicle_id, pickUpDate, dropOffDate, map) {
        let now = moment().utc()
        let pickUp = moment(pickUpDate).utc().subtract(10, 'minutes')
        let dropOff = moment(dropOffDate).utc().add(10, 'minutes')
        if (pickUp.isSameOrBefore(now) && dropOff.isSameOrAfter(now)) {
            this.props.getPositionForTrip(vehicle_id, pickUpDate, dropOffDate)
            const position = this.props.trackingReducer.position || JSON.parse(localStorage.getItem('vehiclePosition'));
            if (position != null) {
                if (this.state.markers.find(x => x.id === 'vehicle') != null) {
                    const marker = this.state.markers.find(x => x.id === 'vehicle').item;
                    if (marker != null) {
                        marker.setPosition(new window.google.maps.LatLng(position.latitude, position.longitude));
                        marker.setMap(map)
                        //this.bounds()
                    }
                }
            }
            else {
                if (this.state.markers.find(x => x.id === 'vehicle') != null) {
                    const marker = this.state.markers.find(x => x.id === 'vehicle').item;
                    if (marker != null) {
                        marker.setMap(null)
                    }
                }
            }
        }
    }

    render() {
        const defaultMapOptions = {
            fullscreenControl: false,
        };
        return (
            <React.Fragment>
                {/* <Grid item md={12} style={{ width: '100%', height: '80vh' }}> */}
                <GoogleMapReact
                    bootstrapURLKeys={{ key: stringConstants.API_Key_Google }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    defaultOptions={defaultMapOptions}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                >
                </GoogleMapReact>
                {/* </Grid> */}
            </React.Fragment>
        );
    }
}

export default TripMap;